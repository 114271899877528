import React, { useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for the default marker icon in React Leaflet
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

interface Location {
  latitude: number;
  longitude: number;
  title?: string;
  location_name?: string;
  index: number;
}

// Create a numbered marker icon
const createNumberedIcon = (number: number) => {
  return L.divIcon({
    className: 'custom-marker-icon',
    html: `<div class="w-6 h-6 rounded-full bg-emerald-600 text-white flex items-center justify-center text-sm font-semibold shadow-md cursor-pointer">${number + 1}</div>`,
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    popupAnchor: [0, -12],
  });
};

interface LocationMapProps {
  locations: Location[];
  className?: string;
}

// Helper component to fit bounds
const BoundsUpdater: React.FC<{ locations: Location[] }> = ({ locations }) => {
  const map = useMap();
  
  useEffect(() => {
    if (locations.length === 0) return;
    
    if (locations.length === 1) {
      map.setView(
        [locations[0].latitude, locations[0].longitude],
        13
      );
      return;
    }

    const bounds = new L.LatLngBounds(
      locations.map(loc => [loc.latitude, loc.longitude])
    );
    map.fitBounds(bounds, { padding: [50, 50] });
  }, [locations, map]);

  return null;
};

export const LocationMap: React.FC<LocationMapProps> = ({ locations, className }) => {
  if (locations.length === 0) return null;

  const handleMarkerClick = useCallback((index: number) => {
    const section = document.getElementById(`section-${index + 1}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // Calculate center point for initial view
  const center: [number, number] = locations.length === 1
    ? [locations[0].latitude, locations[0].longitude]
    : [
        locations.reduce((sum, loc) => sum + loc.latitude, 0) / locations.length,
        locations.reduce((sum, loc) => sum + loc.longitude, 0) / locations.length,
      ];

  return (
    <div id="location-map" className={`${className || ''} relative z-0 scroll-mt-8`}>
      <MapContainer
        center={center}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: '300px', width: '100%', borderRadius: '0.5rem' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location) => (
          <Marker
            key={location.index}
            position={[location.latitude, location.longitude]}
            icon={createNumberedIcon(location.index)}
            eventHandlers={{
              click: () => handleMarkerClick(location.index),
            }}
          >
            <Popup>
              <div 
                className="cursor-pointer hover:text-emerald-600 transition-colors"
                onClick={() => handleMarkerClick(location.index)}
              >
                <span className="font-semibold">Figure {location.index + 1}:</span>{' '}
                {location.title || location.location_name || 'Photo location'}
              </div>
            </Popup>
          </Marker>
        ))}
        <BoundsUpdater locations={locations} />
      </MapContainer>
    </div>
  );
};
