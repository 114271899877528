import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { getStoredApiKey, setStoredApiKey } from "../api";
import { useArticleStore } from '../store/useArticleStore';
import { updateArticle } from "../utils/updateArticle";

export const GenerateButton: React.FC = () => {
  const { article, setArticle } = useArticleStore();
  const [showApiKeyInput, setShowApiKeyInput] = useState(!getStoredApiKey());
  const [apiKey, setApiKey] = useState("");

  const handleApiKeySubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setStoredApiKey(apiKey);
    setShowApiKeyInput(false);
  };

  const handleGenerateArticle = async () => {
    if (!getStoredApiKey()) {
      setShowApiKeyInput(true);
      return;
    }

    await updateArticle(article, setArticle);
  };

  if (showApiKeyInput) {
    return (
      <form onSubmit={handleApiKeySubmit} className="w-full">
        <div className="mb-4">
          <label
            htmlFor="apiKey"
            className="block text-sm font-medium text-gray-700"
          >
            Enter your OpenAI API key
          </label>
          <input
            type="password"
            id="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="sk-..."
            required
          />
        </div>
        <button
          type="submit"
          className="w-full py-3 px-4 rounded-lg font-medium text-white bg-blue-500 hover:bg-blue-600"
        >
          Save API Key
        </button>
      </form>
    );
  }

  return (
    <button
      onClick={handleGenerateArticle}
      disabled={article.isLoading || article.sections.length === 0}
      className={`py-3 px-4 rounded-lg font-medium text-white
        ${
          article.sections.length === 0
            ? "bg-gray-400 cursor-not-allowed"
            : article.isLoading
            ? "bg-blue-400 cursor-wait"
            : "bg-blue-500 hover:bg-blue-600"
        }`}
    >
      {article.isLoading ? (
        <span className="flex items-center justify-center">
          <Loader2 className="animate-spin mr-2 h-5 w-5" />
          Generating Article...
        </span>
      ) : (
          article.sections.some(section => section.description) ? "Update" : "Generate"
      )}
    </button>
  );
};
