import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { useArticleStore } from '../store/useArticleStore';
import { Photo } from '../types';
import { generateId } from '../utils/generateId';
import { updateArticle } from '../utils/updateArticle';
import { getStoredApiKey } from '../api';

export const PhotoUploader: React.FC = () => {
  const { article, setPhotos, setArticle } = useArticleStore();
  const [userNotes, setUserNotes] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // Limit the number of files that can be processed at once
      const MAX_FILES = 10;
      const filesToProcess = acceptedFiles.slice(0, MAX_FILES);
      
      if (acceptedFiles.length > MAX_FILES) {
        console.warn(`Only the first ${MAX_FILES} files will be processed`);
      }

      try {
        const newPhotos: Photo[] = await Promise.all(
          filesToProcess.map(async (file) => {
            try {
              return {
                id: generateId(),
                file,
                preview: URL.createObjectURL(file),
                isProcessing: !!getStoredApiKey(), // Mark as processing if API key exists
                userNotes: userNotes // Add user notes to each photo
              };
            } catch (error) {
              console.error('Failed to create preview for file:', file.name, error);
              throw error;
            }
          })
        );
        
        // Update the store with new photos
        const updatedArticle = { 
          ...article, 
          sections: [...article.sections, ...newPhotos]
        };
        setPhotos(updatedArticle.sections);
        
        // If API key exists, start processing immediately
        if (getStoredApiKey()) {
          await updateArticle(updatedArticle, setArticle);
        }
      } catch (error) {
        console.error('Failed to process dropped files:', error);
        if (getStoredApiKey()) {
          setArticle({ 
            error: 'Failed to process some of the uploaded photos. Please try again with fewer photos.'
          });
        }
      }
    },
    [article, setPhotos, setArticle, userNotes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 20 * 1024 * 1024, // 20MB max file size
    multiple: true
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
          ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">
          {isDragActive
            ? 'Drop the photos here...'
            : 'Drag & drop photos here, or click to select'}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          Supported formats: JPEG, PNG
        </p>
      </div>
      
      <div className="space-y-2">
        <label htmlFor="photo-notes" className="block text-sm font-medium text-gray-700">
          Additional Notes
        </label>
        <textarea
          id="photo-notes"
          value={userNotes}
          onChange={(e) => setUserNotes(e.target.value)}
          placeholder="Add any additional context or notes about these photos..."
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm min-h-[80px]"
        />
      </div>
    </div>
  );
};
