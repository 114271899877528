
export async function resizeImage(
  file: File,
  maxLongSide: number,
  maxShortSide: number
): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(url);

      let width = img.width;
      let height = img.height;

      // Scale down if necessary
      if (width > height) {
        if (width > maxLongSide) {
          height *= maxLongSide / width;
          width = maxLongSide;
        }
        if (height > maxShortSide) {
          width *= maxShortSide / height;
          height = maxShortSide;
        }
      } else {
        if (height > maxLongSide) {
          width *= maxLongSide / height;
          height = maxLongSide;
        }
        if (width > maxShortSide) {
          height *= maxShortSide / width;
          width = maxShortSide;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Could not create blob'));
            return;
          }
          resolve(new File([blob], file.name, { type: 'image/jpeg' }));
        },
        'image/jpeg',
        0.9
      );
    };

    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Failed to load image'));
    };

    img.src = url;
  });
}
