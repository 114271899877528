import React from 'react';
import { X } from 'lucide-react';
import { useArticleStore } from '../store/useArticleStore';
import { useFileCleanup } from '../hooks/useFileCleanup';

export const PhotoPreview: React.FC = () => {
  const { article, setPhotos } = useArticleStore();

  // Cleanup file previews when component unmounts or photos change
  useFileCleanup(article.sections);

  const removePhoto = (id: string) => {
    const photoToRemove = article.sections.find((photo) => photo.id === id);
    if (photoToRemove?.preview) {
      URL.revokeObjectURL(photoToRemove.preview);
    }
    setPhotos(article.sections.filter((photo) => photo.id !== id));
  };

  return (
    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-6">
      {article.sections.map((photo) => (
        <div key={photo.id} className="relative group">
          {photo.preview ? (
            <img
              src={photo.preview}
              alt={photo.title || "Uploaded photo"}
              className={`w-full h-32 object-cover rounded-lg ${
                photo.isProcessing ? 'opacity-50' : ''
              }`}
              onError={(e) => {
                // If the preview fails to load, try to create a new one
                const target = e.target as HTMLImageElement;
                if (photo.file && target.src.startsWith('blob:')) {
                  const newPreview = URL.createObjectURL(photo.file);
                  target.src = newPreview;
                  // Update the preview in the store
                  setPhotos(
                    article.sections.map((p) =>
                      p.id === photo.id ? { ...p, preview: newPreview } : p
                    )
                  );
                }
              }}
            />
          ) : (
            <div className="w-full h-32 bg-gray-100 rounded-lg flex items-center justify-center">
              <span className="text-gray-400">Loading...</span>
            </div>
          )}
          <button
            title="Remove photo"
            onClick={() => removePhoto(photo.id)}
            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <X className="h-4 w-4 text-gray-600" />
          </button>
        </div>
      ))}
    </div>
  );
};
