import { useEffect } from 'react';
import { Photo } from '../types';

export const useFileCleanup = (photos: Photo[]) => {
  useEffect(() => {
    const currentPreviews = new Set(
      photos
        .map(photo => photo.preview)
        .filter(Boolean)
    );

    return () => {
      // Only cleanup previews that are no longer in use
      photos.forEach(photo => {
        if (photo.preview && !currentPreviews.has(photo.preview)) {
          URL.revokeObjectURL(photo.preview);
        }
      });
    };
  }, [photos]);
};