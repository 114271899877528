import React, { useState } from 'react';
import type { Components } from 'react-markdown';
import { useArticleStore } from '../store/useArticleStore';
import { VersionedSection } from '../types';
import { ArticleSection } from "./ArticleSection";
import { LocationMap } from './LocationMap';
import { SkeletonSection } from './SkeletonLoader';

const markdownComponents: Components = {
  h1: (props) => (
    <h2
      className="text-base font-semibold text-gray-700 mt-6 mb-3"
      {...props}
    />
  ),
  h2: (props) => (
    <h2 className="text-sm font-semibold text-gray-600 mt-4 mb-2" {...props} />
  ),
  h3: (props) => (
    <h3 className="text-sm font-medium text-gray-600 mt-3 mb-2" {...props} />
  ),
} as const;

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const Article: React.FC = () => {
  const { article, setArticle } = useArticleStore();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);

  const sections =
    article?.sections?.filter((section) => section.description || section.isProcessing) || [];
  if (sections.length === 0) return null;

  // If there's only one photo, use its title as the article title
  const displayTitle =
    sections.length === 1 ? sections[0].title : article.articleTitle;

  const hasProcessingSections = sections.some(section => section.isProcessing);

  const handleUpdateVersionHistory = (sectionId: string, versionedSection: VersionedSection) => {
    setArticle({
      ...article,
      versionHistory: {
        ...article.versionHistory,
        [sectionId]: versionedSection,
      },
    });
  };

  const handlePrint = async () => {
    setIsPrinting(true);
    try {
      // Get all image elements in the article
      const images = Array.from(document.querySelectorAll('img'));

      // Create a promise for each image that resolves when it's loaded
      const imagePromises = images.map(img => {
        if (img.complete) return Promise.resolve();
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      // Wait for all images to load
      await Promise.all(imagePromises);

      // Proceed with printing
      window.print();
    } catch (error) {
      console.error('Error loading images:', error);
      // Print anyway, in case the error was with a single image
      window.print();
    } finally {
      setIsPrinting(false);
    }
  };

  const handleTitleEdit = () => {
    setEditedTitle(displayTitle || "");
    setIsEditingTitle(true);
  };

  const handleTitleSave = () => {
    if (editedTitle.trim() && editedTitle !== displayTitle) {
      setArticle({
        ...article,
        articleTitle: editedTitle.trim()
      });
    }
    setIsEditingTitle(false);
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      setIsEditingTitle(false);
    }
  };

  return (
    <article className="prose lg:prose-xl max-w-4xl mx-auto mt-12 px-4 sm:px-6 font-serif bg-stone-50/30">
      {(displayTitle || hasProcessingSections) && (
        <div className="mb-12">
          <div className="flex justify-start mb-2 no-print">
            <button
              onClick={handlePrint}
              disabled={isPrinting}
              className="px-3 py-1.5 rounded bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors flex items-center gap-1.5 text-sm disabled:opacity-50 disabled:cursor-wait"
              title="Print article"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clipRule="evenodd" />
              </svg>
              {isPrinting ? "Preparing..." : "Print"}
            </button>
          </div>
          <div className="group relative">
            {isEditingTitle ? (
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleTitleSave}
                onKeyDown={handleTitleKeyDown}
                className="w-full text-3xl md:text-4xl font-bold font-sans text-gray-800 tracking-tight leading-tight bg-transparent border-b border-gray-300 focus:border-emerald-500 focus:outline-none"
                autoFocus
              />
            ) : (
              <h1 className="flex items-center gap-2 group">
                <span className="text-3xl md:text-4xl font-bold font-sans text-gray-800 tracking-tight leading-tight">
                  {displayTitle || "Processing your photos..."}
                </span>
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleTitleEdit}
                    className="p-1 rounded-full bg-gray-100 text-gray-500 opacity-0 group-hover:opacity-100 hover:bg-gray-200 transition-opacity"
                    title="Edit title"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </div>
              </h1>
            )}
          </div>
          {(article.dateRange || sections.some(section => section.location_name)) && (
            <div className="text-sm text-gray-500 mt-2 font-sans">
              {sections.some(section => section.location_name) && (
                [...new Set(sections
                  .map(section => section.location_name)
                  .filter(Boolean))]
                  .join(', ')
              )}
              {article.dateRange && sections.some(section => section.location_name) && (
                <span className="mx-2">•</span>
              )}
              {article.dateRange && (
                article.dateRange.start === article.dateRange.end
                  ? dateFormatter.format(new Date(article.dateRange.start))
                  : `${dateFormatter.format(new Date(article.dateRange.start))} – ${dateFormatter.format(new Date(article.dateRange.end))}`
              )}
            </div>
          )}
        </div>
      )}
      {article.error && (
        <div className="text-red-600 mb-8">{article.error}</div>
      )}
      {sections.some(section => section.latitude && section.longitude) && (
        <LocationMap
          locations={sections
            .filter(section => section.latitude && section.longitude)
            .map((section, index) => ({
              latitude: section.latitude!,
              longitude: section.longitude!,
              title: section.title,
              location_name: section.location_name,
              index
            }))}
          className="mb-12"
        />
      )}
      {sections.map((section, index) => (
        section.isProcessing ? (
          <SkeletonSection key={section.id} />
        ) : (
          <ArticleSection
            key={section.id}
            section={section}
            versionHistory={article.versionHistory?.[section.id]}
            onUpdateVersionHistory={handleUpdateVersionHistory}
            index={index}
            showTitle={sections.length > 1}
            markdownComponents={markdownComponents}
          />
        )
      ))}
      <footer className="mt-16 pt-4 border-t border-gray-200 text-sm text-gray-500 font-sans">
        <p className="flex items-start max-w-sm">
          <svg className="w-6 h-6 mr-2 align-top text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          Image descriptions were generated by OpenAI. As a result, these descriptions may contain inaccuracies or hallucinations.
        </p>
      </footer>
    </article>
  );
};
