import OpenAI from "openai";
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";
import { IMAGE_DESCRIPTION_PROMPTS } from "./prompts";
import { resizeImage } from "../utils/resizeImage";
import { imageToBase64 } from "../utils/imageToBase64";
import { getStoredApiKey } from ".";
import { createCacheRequest, generateCacheKey, getCache } from "./cache";
import { extractLocationData } from "../utils/location";

export interface ImageDescription {
  title: string;
  caption: string;
  description: string;
  sign_translation?: string;
  location_name?: string;
  date?: string;
  latitude?: number;
  longitude?: number;
  camera?: string;
  lens?: string;
}

function imageDescriptionFromResponse(
  response: ImageDescriptionResponse
): ImageDescription {
  const description = {
    title: response.section_title,
    caption: response.photo_caption,
    description: response.description,
    sign_translation: response.sign_translation,
    location_name: response.location_name,
  };
  return description;
}

const ImageDescriptionResponseSchema = z.object({
  section_title: z.string().describe("Section title in plain text"),
  photo_caption: z.string().describe("Caption in markdown"),
  description: z.string().describe("Description in markdown"),
  sign_translation: z.string().optional().describe("Translation of any text visible in the image"),
  location_name: z.string().optional().describe("Location where the photo was taken"),
});

type ImageDescriptionResponse = z.infer<typeof ImageDescriptionResponseSchema>;


export async function generatePhotoDescription(
  imageFile: File,
  otherDescriptions: ImageDescription[] = [],
  userNotes?: string
): Promise<ImageDescription> {
  const apiKey = getStoredApiKey();
  if (!apiKey) {
    throw new Error("No API key found");
  }

  const model = "gpt-4o-mini";
  // Resize and convert image
  const resizedImage = await resizeImage(imageFile, 2048, 768);
  const base64Image = await imageToBase64(resizedImage);

  // Construct the user prompt
  let userPrompt = IMAGE_DESCRIPTION_PROMPTS.user;
  const photoLocation = await extractLocationData(imageFile);
  
  if (photoLocation?.locationName) {
    userPrompt += `\nThis photo was taken in ${photoLocation.locationName}.`;
  }
  if (photoLocation?.date) {
    userPrompt += `\nThis photo was taken on ${photoLocation.date}.`;
  }
  if (userNotes) {
    userPrompt += IMAGE_DESCRIPTION_PROMPTS.user_notes + userNotes;
  }
  if (otherDescriptions.length > 0) {
    userPrompt += IMAGE_DESCRIPTION_PROMPTS.other_articles;
    otherDescriptions.forEach((desc) => {
      userPrompt += `\n\n${desc.title}\n${desc.caption}`;
    });
  }

  // Generate cache key
  const cacheKey = await generateCacheKey({
    model,
    schema: zodResponseFormat(
      ImageDescriptionResponseSchema,
      "image-description"
    ),
    system_prompt: IMAGE_DESCRIPTION_PROMPTS.system,
    user_prompt: userPrompt,
    image_content: base64Image,
  });

  try {
    // Try to get from cache first
    const cache = await getCache();
    const cacheRequest = createCacheRequest(cacheKey);
    const cachedResponse = await cache.match(cacheRequest);
    if (cachedResponse) {
      const data = await cachedResponse.json();
      const photoMetadata = await extractLocationData(imageFile);
      return {
        ...imageDescriptionFromResponse(ImageDescriptionResponseSchema.parse(data)),
        location_name: photoMetadata?.locationName,
        date: photoMetadata?.date,
        latitude: photoMetadata?.latitude,
        longitude: photoMetadata?.longitude,
        camera: photoMetadata?.camera,
        lens: photoMetadata?.lens
      };
    }
  } catch (error) {
    console.debug("Cache miss or error:", error);
  }

  // If not in cache, proceed with the API call
  const openai = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });

  try {
    const response = await openai.beta.chat.completions.parse({
      model,
      messages: [
        {
          role: "system",
          content: IMAGE_DESCRIPTION_PROMPTS.system,
        },
        {
          role: "user",
          content: [
            {
              type: "text",
              text: userPrompt,
            },
            {
              type: "image_url",
              image_url: {
                url: `data:image/jpeg;base64,${base64Image}`,
              },
            },
          ],
        },
      ],
      response_format: zodResponseFormat(
        ImageDescriptionResponseSchema,
        "image-description"
      ),
    });

    const message = response.choices[0]?.message;
    if (!message) {
      throw new Error("No response received from OpenAI");
    }
    if (message.refusal) {
      throw new Error(`AI refused to generate content: ${message.refusal}`);
    }
    const description = message.parsed as ImageDescriptionResponse | undefined;
    if (!description) {
      throw new Error("Invalid response format from OpenAI");
    }

    if (description) {
      // Store in cache
      try {
        const cache = await getCache();
        const cacheRequest = createCacheRequest(cacheKey);
        const response = new Response(JSON.stringify(description), {
          headers: {
            "Content-Type": "application/json",
            "Cache-Key": cacheKey,
          },
        });
        await cache.put(cacheRequest, response);
      } catch (error) {
        console.debug("Failed to store in cache:", error);
      }

      const photoMetadata = await extractLocationData(imageFile);
      return {
        ...imageDescriptionFromResponse(description),
        location_name: photoMetadata?.locationName,
        date: photoMetadata?.date,
        latitude: photoMetadata?.latitude,
        longitude: photoMetadata?.longitude,
        camera: photoMetadata?.camera,
        lens: photoMetadata?.lens
      };
    }
  } catch (error: any) {
    if (error.constructor.name == "LengthFinishReasonError") {
      console.error("Too many tokens: ", error.message);
    }

    // For network errors or other OpenAI API errors, throw immediately
    if ((error as any).status === 429) {
      throw new Error("Rate limit exceeded. Please try again later.");
    }

    throw error;
  }

  throw new Error("Failed to generate description");
}
