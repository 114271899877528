import React from 'react';

interface SkeletonProps {
  className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({ className = "" }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`}></div>
);

export const SkeletonSection: React.FC = () => (
  <div className="mb-16 overflow-auto border-b border-gray-100 pb-12">
    <div className="flex flex-col gap-4">
      <Skeleton className="h-8 w-3/4 mb-4" /> {/* Title */}
      <div className="flex gap-8">
        <Skeleton className="w-[450px] h-[300px] rounded-xl" /> {/* Image */}
        <div className="flex-1 flex flex-col gap-4">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-5/6" />
          <Skeleton className="h-4 w-4/6" />
        </div>
      </div>
      <Skeleton className="h-16 w-[450px] mt-2" /> {/* Caption */}
    </div>
  </div>
);
