// Core imports
import { Camera, ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useState } from 'react';

// Components
import { Article } from './components/Article';
import { BuildTimestamp } from './components/BuildTimestamp';
import { GenerateButton } from './components/GenerateButton';
import { PhotoPreview } from './components/PhotoPreview';
import { PhotoUploader } from './components/PhotoUploader';

// Store
import { useArticleStore } from './store/useArticleStore';

function App() {
  const { article } = useArticleStore();
  const [isInputAreaFolded, setIsInputAreaFolded] = useState(false);
  const [hasGeneratedArticle, setHasGeneratedArticle] = useState(false);

  useEffect(() => {
    // Check if article has sections with descriptions (meaning generation is complete)
    const hasCompletedSections = article.sections.some(section => section.description);

    if (hasCompletedSections && !hasGeneratedArticle) {
      setIsInputAreaFolded(true);
      setHasGeneratedArticle(true);
    }
  }, [article.sections, hasGeneratedArticle]);

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4">
      <div className="max-w-4xl mx-auto">
        {article.sections.length > 0 && (
          <button
            onClick={() => setIsInputAreaFolded(prev => !prev)}
            className="w-full flex items-center justify-between p-3 text-sm text-gray-500 bg-white rounded-t-xl shadow-sm no-print"
          >
            <span>{isInputAreaFolded ? "Show" : "Hide"} input area</span>
            {isInputAreaFolded ? (
              <ChevronDown className="h-4 w-4" />
            ) : (
              <ChevronUp className="h-4 w-4" />
            )}
          </button>
        )}

        {(!article.sections.length || !isInputAreaFolded) && (
          <>
            <div className="text-center mb-8 no-print">
              <Camera className="h-12 w-12 text-blue-500 mx-auto" />
              <h1 className="mt-4 text-3xl font-bold text-gray-900">
                Travel Photo Explainer
              </h1>
              <p className="mt-2 text-gray-600">
                Upload your travel photos to learn about about your trip
              </p>
            </div>

            <div className="bg-white rounded-b-xl shadow-sm p-6 mb-8 no-print">
              <PhotoUploader />
              <PhotoPreview />

              {article.sections.length > 0 && (
                <div className="mt-6">
                  <div className="flex flex-col gap-4">
                    <GenerateButton />
                  </div>
                  {article.error && (
                    <p className="text-red-500 text-sm mt-2">{article.error}</p>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <Article />
      </div>
      <footer className="text-center text-sm text-gray-500 py-8 border-t border-gray-100 print:hidden">
        <div className="container mx-auto px-4 space-y-4">
          <div className="space-y-1">
            <p>
              Made with ❤️ by <a href="https://osteele.com" className="text-emerald-600 hover:text-emerald-700 hover:underline" target="_blank" rel="noopener noreferrer">Oliver Steele</a>
            </p>
            <p className="text-xs text-gray-400">
              <BuildTimestamp />
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
