export const IMAGE_DESCRIPTION_PROMPTS = {
  system: `
You are a subject matter expert in culture and history, and anjoy sharing your knowledge.
You carefully provide accurate, factual, thoughtful, nuanced answers.
You err on the side of saying too little rather than saying what you don't know.
`,
  user: `
Tell me about this.

Choose a title that is clear and descriptive. The title should describe the content of the image.

The description should be informative. It should give the reader cultural and historical
background to help understand the contents of the scene. Avoid flowery language.

You may use markdown in the caption and description fields.
Use markdown to mark special terms and phrases that are unlikely to be familar to the reader,
but only the first time a term is introduced.

If the image contains foreign words or phrases, please translate them into English and explain their meaning.
If there is no visible text to translate, don't mention it.

If the image contains a sign in a foreign language, please translate all the text of the sign into English and explain its meaning.

Describe the setting, not the people. You can describe people who are in special costume or otherwise appear to be part of the cultural or historical context.

Don't include the location name or date in the section title. We'll put that in the article title instead.

Don't use headings such as "Cultural Significance", that could be true of any travel photo. Multiple images and their descriptions will
be collected into an article, and it makes it repetitive if they all use similar headings.
`,
  user_notes: `\nAdditional context from the user: `,
  other_articles: `\n\n
Select a title that harmonizes with but is not redundant with the other sections.
Don't repeat information that is in the previous section.
Here are the other sections of the article.
`,
};

export const ARTICLE_TITLE_PROMPTS = {
  system: IMAGE_DESCRIPTION_PROMPTS.system,
  user: `
Create a factual title for an article that encompasses the following sections.

For each section, I will provide:
- A title that describes that specific scene or moment
- A brief caption that provides context
- The location name and date of the photo, if available

Create a title that:
1. Is clear and descriptive
2. Reflects the topics or theme
3. Is not too similar to any of the individual section titles

Respond with just the title, without any additional explanation or formatting.`,
};

export const REVISE_SECTION_PROMPTS = {
  system: IMAGE_DESCRIPTION_PROMPTS.system,
  user: `
Please edit the following travel article section according to these instructions:

"{instructions}"

Current title: {currentTitle}

Current text: {currentText}

Provide the new title and text, maintaining the same style and tone but incorporating the requested changes.
`,
};
