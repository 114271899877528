import OpenAI from "openai";
import { zodResponseFormat } from "openai/helpers/zod.mjs";
import { z } from "zod";
import { getStoredApiKey } from ".";
import { REVISE_SECTION_PROMPTS } from "./prompts";

interface EditArticleSectionParams {
  currentTitle: string;
  currentText: string;
  instructions: string;
}

const EditedSectionResponseSchema = z.object({
  title: z.string().describe("The edited title for the section"),
  text: z.string().describe("The edited text content for the section"),
});

type EditedSectionResponse = z.infer<typeof EditedSectionResponseSchema>;

export async function editArticleSection({
  currentTitle,
  currentText,
  instructions,
}: EditArticleSectionParams): Promise<EditedSectionResponse> {
  const apiKey = getStoredApiKey();
  if (!apiKey) {
    throw new Error("OpenAI API key is required");
  }

  const openai = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });

  const userPrompt = REVISE_SECTION_PROMPTS.user
    .replace("{currentTitle}", currentTitle)
    .replace("{currentText}", currentText)
    .replace("{instructions}", instructions);

  const response = await openai.beta.chat.completions.parse({
    model: "gpt-4o-mini",
    messages: [
      {
        role: "system",
        content: REVISE_SECTION_PROMPTS.system,
      },
      {
        role: "user",
        content: userPrompt,
      },
    ],
    response_format: zodResponseFormat(EditedSectionResponseSchema, "section"),
  });

  const message = response.choices[0].message;
  if (!message) {
    throw new Error("No response received from OpenAI");
  }
  if (message.refusal) {
    throw new Error(`OpenAI refused to respond: ${message.refusal}`);
  }
  if (!message.parsed) {
    throw new Error("No parsed received from OpenAI");
  }
  return message.parsed as EditedSectionResponse;
}
