import {
  type ImageDescription,
  generateDescription,
  generateArticleTitle,
  getStoredApiKey,
} from "../api";
import { Article, Photo } from "../types";

export async function updateArticle(
  article: Article,
  setArticle: (article: Partial<Article>) => void
) {
  const apiKey = getStoredApiKey();
  if (!apiKey) {
    setArticle({ error: "Please enter your OpenAI API key to generate descriptions." });
    return { showApiKeyInput: true };
  }

  let sections = article.sections;
  let versionHistory = article.versionHistory || {};
  setArticle({ isLoading: true, error: undefined });

  // Process photos in batches of 3 to avoid overwhelming the system
  const BATCH_SIZE = 3;
  let processedSections = [...sections];

  // Process photos in batches
  for (let i = 0; i < sections.length; i += BATCH_SIZE) {
    const batch = sections.slice(i, i + BATCH_SIZE);
    
    try {
      // Process batch in parallel
      const results = await Promise.all(
        batch.map(async (photo) => {
          // Skip only if already has description
          if (photo.description) {
            return photo;
          }

          const index = sections.indexOf(photo);
          const previousDescriptions = sections.slice(0, index).map((p) => ({
            title: p.title || "",
            caption: p.description || "",
            description: p.description || "",
            sign_translation: p.sign_translation || "",
          }));

          try {
            const description = await generateDescription(
              photo.file,
              previousDescriptions,
              photo.userNotes
            );

            const newSection = {
              ...photo,
              ...description,
              isProcessing: false,
            };

            // Initialize version history for this section
            versionHistory[photo.id] = {
              versions: [{
                title: description.title,
                description: description.description,
                timestamp: new Date().toISOString(),
              }],
              currentVersion: 0,
            };

            return newSection;
          } catch (error) {
            console.error(`Failed to process photo ${index}:`, error);
            return { 
              ...photo, 
              isProcessing: false,
              error: error instanceof Error ? error.message : 'Failed to process photo'
            };
          }
        })
      );

      // Update processed sections
      results.forEach((result, idx) => {
        const originalIndex = sections.indexOf(batch[idx]);
        if (originalIndex !== -1) {
          processedSections[originalIndex] = result;
        }
      });

      // Update article state after each batch
      setArticle({ sections: processedSections, versionHistory });
    } catch (error) {
      console.error("Failed to process batch:", error);
    }
  }

  // Generate article title if there are multiple photos
  let articleTitle = article.articleTitle;
  if (sections.length >= 2) {
    try {
      articleTitle = await generateArticleTitle(
        processedSections
          .map((section) => photoToImageDescription(section))
      );
    } catch (error) {
      console.error("Failed to generate article title:", error);
    }
  }

  // Calculate date range from photos that have dates
  const dates = processedSections
    .map((section) => section.date)
    .filter((date): date is string => !!date)
    .map((date) => new Date(date))
    .filter((date) => !isNaN(date.getTime()))
    .sort((a, b) => a.getTime() - b.getTime());

  const dateRange = dates.length > 0
    ? {
        start: dates[0].toISOString().split("T")[0],
        end: dates[dates.length - 1].toISOString().split("T")[0],
      }
    : undefined;

  setArticle({
    sections: processedSections,
    articleTitle,
    isLoading: false,
    dateRange,
  });
  return { showApiKeyInput: false };
}

// Convert Photo to ImageDescription format
const photoToImageDescription = (photo: Photo): ImageDescription => ({
  title: photo.title || "",
  caption: photo.description || "",
  description: photo.description || "",
  sign_translation: photo.sign_translation,
});
