import { create } from 'zustand';
import { Photo, Article } from '../types';
import {} from "../api/generateArticleTitle";

interface ArticleStore {
  article: Article;
  setPhotos: (photos: Photo[]) => void;
  setArticle: (article: Partial<Article>) => void;
  reset: () => void;
}

const initialState: Article = {
  articleTitle: undefined,
  sections: [],
  isLoading: false,
  error: undefined,
};

export const useArticleStore = create<ArticleStore>((set) => ({
  article: initialState,
  setPhotos: async (photos) => {
    set((state) => ({
      article: { ...state.article, sections: photos },
    }));
  },
  setArticle: (articleData) =>
    set((state) => ({
      article: { ...state.article, ...articleData },
    })),
  reset: () => set({ article: initialState }),
}));
