export { generatePhotoDescription as generateDescription, type ImageDescription } from "./generatePhotoDescription";
export { generateArticleTitle } from "./generateArticleTitle";

export const STORAGE_KEY = "openai_api_key";
export function getStoredApiKey(): string | null {
  return localStorage.getItem(STORAGE_KEY);
}

export function setStoredApiKey(key: string): void {
  localStorage.setItem(STORAGE_KEY, key);
}
