import OpenAI from "openai";
import { getStoredApiKey } from ".";
import { ImageDescription } from "./generatePhotoDescription";
import { ARTICLE_TITLE_PROMPTS } from "./prompts";
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod.mjs";

const ArticleTitleResponseSchema = z.object({
  title: z.string().describe("Article title in plain text"),
});

type ArticleTitleResponse = z.infer<typeof ArticleTitleResponseSchema>;

export async function generateArticleTitle(
  descriptions: ImageDescription[]
): Promise<string> {
  const apiKey = getStoredApiKey();
  if (!apiKey) {
    throw new Error("OpenAI API key not found");
  }

  const openai = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });
  const model = "gpt-4o-mini";

  // Format the descriptions into a string for the prompt
  const sectionsText = descriptions
    .map(
      (desc) => `---
Title: ${desc.title}
Description: ${desc.caption}
${desc.location_name ? `Location: ${desc.location_name}` : ""}
${desc.date ? `Date: ${desc.date}` : ""}
---`
    )
    .join("\n\n");

  const userPrompt = ARTICLE_TITLE_PROMPTS.user + "\n\n" + sectionsText;

  try {
    const response = await openai.beta.chat.completions.parse({
      model,
      messages: [
        { role: "system", content: ARTICLE_TITLE_PROMPTS.system },
        {
          role: "user",
          content: userPrompt,
        },
      ],
      response_format: zodResponseFormat(
        ArticleTitleResponseSchema,
        "article-title"
      ),
    });

    const message = response.choices[0]?.message;
    if (!message) {
      throw new Error("No response received from OpenAI");
    }
    if (message.refusal) {
      throw new Error(`OpenAI refused to respond: ${message.refusal}`);
    }

    const title = message.parsed as ArticleTitleResponse | undefined;
    if (!title) {
      throw new Error("No title generated");
    }

    return title.title;
  } catch (error) {
    console.error("Error generating article title:", error);
    throw new Error("Failed to generate article title");
  }
}
