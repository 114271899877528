import { parse as parseExif } from 'exifr';

export interface PhotoMetadata {
  latitude?: number;
  longitude?: number;
  locationName?: string;
  date?: string;
  camera?: string;
  lens?: string;
}

const LOCATION_CACHE_NAME = "location-data-v1";

async function getLocationCache(): Promise<Cache | null> {
  if ("caches" in window) {
    return await caches.open(LOCATION_CACHE_NAME);
  }
  return null;
}

async function getLocationName(latitude: number, longitude: number): Promise<string | null> {
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
  const cacheKey = url;
  try {
    const cache = await getLocationCache();
    if (cache) {
      const cachedResponse = await cache.match(cacheKey);
      
      if (cachedResponse) {
        const data = await cachedResponse.json();
        return data.locationName;
      }
    }

    const response = await fetch(
      url,
      {
        headers: {
          'User-Agent': 'TravelJournal/1.0 (https://tools.osteele.com/travel-photo-explainer)',
        }
      }
    );
    const data = await response.json();
    
    // Get the most specific name available
    const locationName = data.name || // specific landmark name if available
                        data.address?.tourism || // tourist attraction
                        data.address?.suburb || // suburb
                        data.address?.city || // city
                        data.address?.town || // town
                        data.address?.village || // village
                        data.address?.county; // county as fallback
    
    if (cache) {
      // Cache the result only if cache is available
      await cache.put(
        cacheKey,
        new Response(JSON.stringify({ locationName }))
      );
    }
    return locationName || null;
  } catch (error) {
    return null;
  }
}

export async function extractLocationData(imageFile: File): Promise<PhotoMetadata | null> {
  try {
    const exifData = await parseExif(imageFile, { gps: true, exif: true });
    
    const result: PhotoMetadata = {};
    
    if (exifData?.latitude && exifData?.longitude) {
      result.latitude = exifData.latitude;
      result.longitude = exifData.longitude;
      const locationName = await getLocationName(exifData.latitude, exifData.longitude);
      result.locationName = locationName || undefined;
    }

    if (exifData?.CreateDate || exifData?.DateTimeOriginal) {
      const dateStr = exifData.CreateDate || exifData.DateTimeOriginal;
      result.date = new Date(dateStr).toLocaleDateString();
    }

    // Add camera info from EXIF
    if (exifData?.LensMake || exifData?.LensModel) {
      result.lens = [exifData.LensMake, exifData.LensModel].filter(Boolean).join(' ');
    }
    
    // For iPhone photos, use LensModel as the camera name
    if (exifData?.LensMake === 'Apple') {
      result.camera = 'iPhone';  // We could parse the exact model from LensModel if needed
    }

    return Object.keys(result).length > 0 ? result : null;
  } catch (error) {
    return null;
  }
}
