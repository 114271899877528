import React, { useState } from "react";
import type { Components } from "react-markdown";
import ReactMarkdown from "react-markdown";
import { editArticleSection } from "../api/editArticleSection";
import { Photo, Section, VersionedSection } from "../types";

// Add Crimson Text font for decorative quotes
const HEAD_ELEMENT = typeof document !== 'undefined' ? document.head : null;
if (HEAD_ELEMENT && !document.getElementById('crimson-text-font')) {
  const link = document.createElement('link');
  link.id = 'crimson-text-font';
  link.rel = 'stylesheet';
  link.href = 'https://fonts.googleapis.com/css2?family=Crimson+Text:ital@0;1&display=swap';
  HEAD_ELEMENT.appendChild(link);
}

interface ArticleSectionProps {
  section: Section;
  versionHistory?: VersionedSection;
  onUpdateVersionHistory: (sectionId: string, versionedSection: VersionedSection) => void;
  index: number;
  showTitle: boolean;
  markdownComponents: Components;
}

export const ArticleSection: React.FC<ArticleSectionProps> = ({
  section,
  versionHistory,
  onUpdateVersionHistory,
  index,
  showTitle,
  markdownComponents,
}) => {
  const [editingState, setEditingState] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [instructions, setInstructions] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const currentVersion = versionHistory?.versions[versionHistory.currentVersion];
  const hasMultipleVersions = versionHistory && versionHistory.versions.length > 1;

  const handleTitleEdit = () => {
    setEditedTitle(currentVersion?.title || section.title || "");
    setIsEditingTitle(true);
  };

  const handleTitleSave = async () => {
    if (editedTitle.trim() && editedTitle !== (currentVersion?.title || section.title)) {
      try {
        setIsSubmitting(true);
        // Create a new version with just the title change
        const newVersionHistory: VersionedSection = {
          versions: [
            ...(versionHistory?.versions || []),
            {
              title: editedTitle.trim(),
              description: currentVersion?.description || section.description || "",
              timestamp: new Date().toISOString(),
              instructions: "Updated title",
            },
          ],
          currentVersion: versionHistory ? versionHistory.versions.length : 0,
        };
        onUpdateVersionHistory(section.id, newVersionHistory);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to update title");
      } finally {
        setIsSubmitting(false);
      }
    }
    setIsEditingTitle(false);
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      setIsEditingTitle(false);
    }
  };

  const handleSubmitEdit = async () => {
    if (!instructions.trim() || isSubmitting) return;
    try {
      setIsSubmitting(true);
      setError(null);
      const result = await editArticleSection({
        currentTitle: currentVersion?.title || section.title || "",
        currentText: currentVersion?.description || section.description || "",
        instructions,
      });

      // Create or update version history
      const newVersionHistory: VersionedSection = {
        versions: [
          ...(versionHistory?.versions || []),
          {
            title: result.title,
            description: result.text,
            timestamp: new Date().toISOString(),
            instructions,
          },
        ],
        currentVersion: versionHistory ? versionHistory.versions.length : 0,
      };
      onUpdateVersionHistory(section.id, newVersionHistory);

      setEditingState(false);
      setInstructions("");
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to edit section");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVersionChange = (delta: number) => {
    if (!versionHistory) return;
    const newVersion = versionHistory.currentVersion + delta;
    if (newVersion >= 0 && newVersion < versionHistory.versions.length) {
      onUpdateVersionHistory(section.id, {
        ...versionHistory,
        currentVersion: newVersion,
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitEdit();
    }
  };

  // Use versioned content if available, otherwise use section content
  const displayTitle = currentVersion?.title || section.title;
  const displayDescription = currentVersion?.description || section.description;

  return (
    <section
      id={`section-${index + 1}`}
      className={`mb-16 overflow-auto border-b border-gray-100 pb-12 last:border-b-0 scroll-mt-8 ${
        isSubmitting ? 'opacity-50' : ''
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <div className="flex-1">
          {showTitle && displayTitle && (
            <div className="group relative">
              {isEditingTitle ? (
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleSave}
                  onKeyDown={handleTitleKeyDown}
                  className="w-full text-xl font-medium font-sans text-emerald-800 bg-transparent border-b border-gray-300 focus:border-emerald-500 focus:outline-none"
                  autoFocus
                />
              ) : (
                <h2 className="flex items-center gap-2 group">
                  <span className="text-xl font-medium font-sans text-emerald-800">
                    {displayTitle}
                  </span>
                  <button
                    onClick={handleTitleEdit}
                    className="p-1 rounded-full bg-gray-100 text-gray-500 opacity-0 group-hover:opacity-100 hover:bg-gray-200 transition-opacity"
                    title="Edit title"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </h2>
              )}
            </div>
          )}
        </div>
        {hasMultipleVersions && (
          <div className="flex items-center gap-1">
            <button
              onClick={() => handleVersionChange(-1)}
              disabled={!versionHistory || versionHistory.currentVersion === 0}
              className="text-sm px-3 py-1 rounded bg-gray-50 hover:bg-gray-100 disabled:opacity-30 disabled:hover:bg-gray-50 flex items-center gap-1"
              title="Previous version"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
              Previous
            </button>
            <span className="text-sm text-gray-500">
              {(versionHistory?.currentVersion || 0) + 1} / {versionHistory?.versions.length}
            </span>
            <button
              onClick={() => handleVersionChange(1)}
              disabled={!versionHistory || versionHistory.currentVersion === versionHistory.versions.length - 1}
              className="text-sm px-3 py-1 rounded bg-gray-50 hover:bg-gray-100 disabled:opacity-30 disabled:hover:bg-gray-50 flex items-center gap-1"
              title="Next version"
            >
              Next
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        )}
      </div>

      <PhotoWithCaption photo={section} index={index} float={index % 2 === 0 ? "left" : "right"} />
      <div className={`prose-p:leading-relaxed prose-p:mb-6 prose-strong:text-emerald-800 prose-strong:font-semibold ${
        isSubmitting ? 'pointer-events-none' : ''
      }`}>
        <ReactMarkdown components={markdownComponents}>
          {displayDescription || ""}
        </ReactMarkdown>
        {section.sign_translation && (
          <>
            <div className="clear-both" />
            <div className="mt-8 mb-6">
              <div className="text-sm font-medium text-emerald-700 tracking-wide uppercase mb-4">
                Translation
              </div>
              <blockquote className="prose prose-quoteless font-['Crimson_Text'] text-gray-600 leading-relaxed">
                <ReactMarkdown components={{
                  p: ({ children }) => <span className="inline italic">{children}</span>
                }}>{section.sign_translation}</ReactMarkdown>
              </blockquote>
            </div>
          </>
        )}
      </div>

      <div className="mt-4 border-t border-gray-100 pt-4 no-print">
        <div className="flex items-center justify-between mb-4">
          <div className="text-sm text-gray-500">
            {hasMultipleVersions && currentVersion && (
              <>
                Last edited: {new Date(currentVersion.timestamp).toLocaleString()}
                {currentVersion.instructions && (
                  <div className="mt-1 text-gray-400">
                    Changes: {currentVersion.instructions}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            {hasMultipleVersions && (
              <>
                <button
                  onClick={() => handleVersionChange(-1)}
                  disabled={!versionHistory || versionHistory.currentVersion === 0}
                  className="text-sm px-3 py-1 rounded bg-gray-50 hover:bg-gray-100 disabled:opacity-30 disabled:hover:bg-gray-50 flex items-center gap-1"
                  title="Previous version"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                  Previous
                </button>
                <button
                  onClick={() => handleVersionChange(1)}
                  disabled={!versionHistory || versionHistory.currentVersion === versionHistory.versions.length - 1}
                  className="text-sm px-3 py-1 rounded bg-gray-50 hover:bg-gray-100 disabled:opacity-30 disabled:hover:bg-gray-50 flex items-center gap-1"
                  title="Next version"
                >
                  Next
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </>
            )}
            <button
              onClick={() => setEditingState(!editingState)}
              disabled={isSubmitting}
              className={`text-sm px-3 py-1 rounded transition-colors ${
                editingState
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-emerald-50 text-emerald-700 hover:bg-emerald-100'
              } disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {editingState ? "Cancel Edit" : "Edit Section"}
            </button>
          </div>
        </div>

        {editingState && (
          <div className="bg-gray-50 p-4 rounded-lg w-full">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Edit Instructions
            </label>
            <textarea
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isSubmitting}
              className="w-full p-2 border rounded-md mb-4 disabled:bg-gray-100 disabled:cursor-not-allowed min-h-[100px] resize-y"
              placeholder="Describe how you want to change this section... Press Enter to submit"
              rows={3}
            />
            {error && (
              <div className="text-red-600 text-sm mb-4">{error}</div>
            )}
            <div className="flex items-center justify-between">
              <button
                onClick={handleSubmitEdit}
                disabled={isSubmitting || !instructions.trim()}
                className="px-4 py-1.5 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 text-sm"
              >
                {isSubmitting ? (
                  <>
                    <span className="inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span>Updating...</span>
                  </>
                ) : displayDescription ? (
                  "Update"
                ) : (
                  "Generate"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

function PhotoWithCaption({ photo, index, float }: { photo: Photo; index: number; float: "left" | "right" }) {
  const handleLocationClick = () => {
    const mapElement = document.getElementById('location-map');
    if (mapElement) {
      mapElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return <div
    className={`relative ${float === "left" ? "float-left mr-8" : "float-right ml-8"}`}
  >
    <img
      src={photo.preview}
      alt={photo.title || "Travel photo"}
      className="w-[450px] rounded-xl shadow-md hover:shadow-xl transition-all duration-300 object-cover border border-gray-100" />
    {photo.caption && (
      <figcaption className="mt-4 text-sm text-gray-600 max-w-[450px] italic leading-relaxed bg-white/80 p-3 rounded-lg">
        <div className="inline">
          <span className="font-semibold not-italic">Figure {index + 1}: </span>
          <ReactMarkdown components={{
            p: ({ children }) => <span className="inline">{children}</span>
          }}>{photo.caption}</ReactMarkdown>
        </div>
        {(photo.location_name || photo.date || photo.camera || photo.lens) && (
          <div className="text-sm text-gray-500 italic mt-2 flex items-center gap-1">
            {photo.latitude && photo.longitude && (
              <button
                onClick={handleLocationClick}
                className="hover:text-emerald-600 transition-colors p-1 -ml-1"
                title="Show on map"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>
              </button>
            )}
            {[
              photo.location_name,
              photo.date,
              ...(photo.camera || photo.lens) ? (
                photo.camera === 'iPhone'
                  ? [`iPhone ${photo.lens?.replace('Apple iPhone', '').replace('back triple camera', '').trim()}`]
                  : [photo.camera, photo.lens]
              ) : []
            ]
              .filter(Boolean)
              .join(' • ')}
          </div>
        )}
      </figcaption>
    )}
  </div>;
}
