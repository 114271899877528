/**
 * Generate a SHA-256 hash of a string using the crypto module
 */
export async function hashString(str: string): Promise<string> {
  const buf = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(str)
  );
  return Array.from(new Uint8Array(buf))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

/**
 * Generate a cache key from an object by creating a string of key-value pairs,
 * where values are hashed, and then hashing the resulting string
 */
export async function generateCacheKey(
  obj: Record<string, unknown>
): Promise<string> {
  // Sort keys to ensure consistent ordering
  const sortedEntries = Object.entries(obj).sort(([a], [b]) =>
    a.localeCompare(b)
  );

  // Create array of "key=hash(value)" strings
  const keyValuePairs = await Promise.all(
    sortedEntries.map(async ([key, value]) => {
      const stringValue =
        typeof value === "string" ? value : JSON.stringify(value);
      const hash = await hashString(stringValue);
      return `${key}=${hash}`;
    })
  );

  // Join with separator and hash the result
  return hashString(keyValuePairs.join("|"));
}
