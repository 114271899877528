declare const __BUILD_TIME__: string;

export function BuildTimestamp() {
  const buildTime = new Date(__BUILD_TIME__);
  const formattedTime = buildTime.toLocaleString();

  return (
    <span className="text-xs text-gray-400 select-none">
      Built: {formattedTime}
    </span>
  );
}
